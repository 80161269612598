import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a71a2c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"16px"} }
const _hoisted_2 = {
  key: 0,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1),
    (_ctx.showSpinner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}